<!--
 * @Description: 多图上传，不需要剪裁，相册列表展示。上传成功后返回所有的url数组
 * @Author: 琢磨先生
 * @Date: 2023-12-15 10:26:46
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-12-15 10:30:12
-->
<template>
  <div class="pic_list">
    <div class="image" v-for="(item, i) in images" :key="item">
      <el-image
        :src="item"
        fit="cover"
        :preview-src-list="images"
        :initial-index="i"
      ></el-image>
      <div class="btn_del">
        <el-icon class="icon-del" @click="delImage(i)"><Close /></el-icon>
      </div>
    </div>
    <div class="btn_upload" @click="dialogVisible = true">
      <el-icon class="upload-icon"><Upload /></el-icon>
      <div class="text">上传图片</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      images: [],
    };
  },
  props: {
    pictures: Array,
  },
  emits: ["close", "change"],
  methods: {},
};
</script>

<style scoped>
.btn_upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
}

.btn_upload:hover {
  border-color: var(--el-color-primary);
}

.btn_upload .text {
  font-size: 12px;
  color: #c0c4cc;
  line-height: 12px;
  margin-top: 10px;
}

.btn_upload .el-icon.upload-icon {
  font-size: 26px;
  color: #c0c4cc;
  text-align: center;
}

.original-upload {
  width: 100%;
}
.el-upload {
  width: 100%;
  margin-top: 10px;
}

.original-upload .el-upload.is-drag {
  display: block;
}
.original-upload .el-upload .el-upload-dragger {
  width: 100% !important;
}

.original-upload .el-icon.el-icon--close {
  display: none;
}

/* .psd-dialog .el-upload-list__item {
  padding: 0 10px;
} */
.psd-dialog .el-upload-list__item-thumbnail {
  width: 50px;
  height: 50px;
}
</style>

<style scoped>
/* 图片列表 */
.pic_list {
  width: 100%;
  display: flex;
  flex-flow: wrap;
}
.pic_list .tip {
  width: 100%;
}
.pic_list .image {
  margin: 0 10px 10px 0;
  width: 100px;
  height: 100px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

.pic_list .image .el-image {
  width: 100%;
  height: 100%;
}

.pic_list .image .btn_del {
  position: absolute;
  right: 0px;
  top: 0px;
  background: var(--el-color-danger);
  color: white;
  height: 15px;
  width: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 5px;
}
.pic_list .image .el-icon {
  font-size: 12px;
}

.pic_list .image:hover .icon-del.el-icon {
  display: block;
}
</style>
